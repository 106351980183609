import request from '/@/utils/request';

/**
 * 后端控制菜单模拟json，路径在 https://gitee.com/lyt-top/vue-next-admin-images/tree/master/menu
 * 后端控制路由，isRequestRoutes 为 true，则开启后端控制路由
 * @method getMenuAdmin 获取后端动态路由菜单(admin)
 * @method getMenuTest 获取后端动态路由菜单(test)
 */
export function useMenuApi() {
    return {
        getUserMenus: (params ?: object) => {
            return request({
                url: '/common/user/menus',
                method: 'get',
                params
            })
        },
        postDomainMenu: (data: object) => {
            return request({
                url: '/menus',
                method: 'post',
                data: data
            })
        },
        putDomainMenu: (id: number, data: object) => {
            return request({
                url: '/menus/' + id,
                method: 'put',
                data: data
            })
        },
        getMenusTable: (params?: object) => {
            return request({
                url: '/menus/trees',
                method: 'get',
                params
            })
        }
    };
}
