import {createApp} from 'vue';
import pinia from '/@/stores/index';
import App from './App.vue';
import {router} from './router';
import {directive} from '/@/utils/directive';
import other from '/@/utils/other';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import '/@/theme/index.scss';
import mitt from 'mitt';
import './plugin/emoji-awesome/css/google.min.css';

import dayjs from 'dayjs'
// @ts-ignore
window.dayjs = dayjs

// @ts-ignore
import Chat from 'vue3-beautiful-chat'
// @ts-ignore
import NP from 'number-precision';
// @ts-ignore
window.NP = NP;

const app = createApp(App);

app.use(Chat)

directive(app);
other.elSvg(app);

app.use(pinia).use(router).use(ElementPlus).mount('#app');

app.config.globalProperties.mittBus = mitt();
