//import $protobuf from "protobufjs/light"

import { roots, Root } from "protobufjs/dist/light/protobuf.min";

// var $root = ($protobuf.roots["default"] || ($protobuf.roots["default"] = new $protobuf.Root()))
var $root = (roots["default"] || (roots["default"] = new Root()))
.addJSON({
  protocol: {
    nested: {
      Message: {
        fields: {
          fromUser: {
            type: "string",
            id: 1
          },
          toUser: {
            type: "string",
            id: 2
          },
          content: {
            type: "string",
            id: 3
          },
          schema: {
            type: "string",
            id: 4
          },
          type: {
            type: "int32",
            id: 5
          },
          mode: {
            type: "int32",
            id: 6
          },
          url: {
            type: "string",
            id: 7
          },
          file: {
            type: "bytes",
            id: 8
          }
        }
      }
    }
  }
});

export default $root;