import request from '/@/utils/request';

export function useLoginApi() {
    return {
        signIn: (params: object) => {
            return request({
                url: '/auth/login/using-password',
                method: 'post',
                data: params,
            });
        },
        signOut: (params: object) => {
            return request({
                url: '/signout',
                method: 'post',
                data: params,
            });
        },
        getCaptcha:()=>{
            return request({
                url: '/auth/verify-codes/captcha',
                method: 'post',
            });
        },
        sendLoginCode: (data: object) => {
            return request({
                url: '/auth/verify-codes/phone',
                method: 'post',
                data: data
            })
        },
        signInWithCode: (data: object) => {
            return request({
                url: '/auth/login/using-phone',
                method: 'post',
                data: data
            })
        },
        getUserInfos: (params?: object) => {
            return request({
                url: '/common/user/info',
                method: 'get',
                params
            });
        },
    };
}
