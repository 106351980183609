import type {App} from 'vue';
import {useUserInfo} from '/@/stores/userInfo';
import {judementSameArr} from '/@/utils/arrayOperation';

/**
 * 用户权限指令
 * @directive 单个权限验证（v-auth="xxx"）
 * @directive 多个权限验证，满足一个则显示（v-auths="[xxx,xxx]"）
 * @directive 多个权限验证，全部满足则显示（v-auth-all="[xxx,xxx]"）
 */
export function authDirective(app: App) {

    // 单个权限验证（v-auth="xxx"）
    app.directive('auth', {
        mounted(el, binding) {
            if (binding.value == 'black') {
                el.parentNode.removeChild(el);
                return;
            }

            const stores = useUserInfo();

            // 用户 1 不检查
            if (stores.userInfos.userID == 1) {
                return;
            }

            // 角色 admin 不检查
            if (stores.userInfos.roles.some((v: string) => v === "admin")) {
                return;
            }

            // 角色 admin 不检查
            if (stores.userInfos.roles.some((v: string) => v === "administrator")) {
                return;
            }

            console.log('Permission::', stores.userInfos.permissions)
            console.log('Current Permission::', binding.value)

            if (!stores.userInfos.permissions.some((v: string) => v === binding.value)) {
                el.parentNode.removeChild(el);
            }
        },
    });

    app.directive('admin', {
        mounted(el, binding) {
            const stores = useUserInfo();

            // 用户 1 不检查
            if (stores.userInfos.userID == 1) {
                return;
            }

            // 角色 admin 不检查
            if (stores.userInfos.roles.some((v: string) => v === "admin")) {
                return;
            }

            // 角色 admin 不检查
            if (stores.userInfos.roles.some((v: string) => v === "administrator")) {
                return;
            }

            el.parentNode.removeChild(el);
        }
    });

    // 多个权限验证，满足一个则显示（v-auths="[xxx,xxx]"）
    app.directive('auths', {
        mounted(el, binding) {
            let flag = false;
            const stores = useUserInfo();

            // 用户 1 不检查
            if (stores.userInfos.userID == 1) {
                return;
            }

            // 角色 admin 不检查
            if (stores.userInfos.roles.some((v: string) => v === "admin")) {
                return;
            }

            // 角色 admin 不检查
            if (stores.userInfos.roles.some((v: string) => v === "administrator")) {
                return;
            }

            if (binding.value) {
                if (binding.value == 'all' || binding.value == 'ALL') {
                    flag = true;
                } else {
                    stores.userInfos.permissions.map((val: string) => {
                        binding.value.map((v: string) => {
                            if (val === v) flag = true;
                        });
                    });
                }

            }

            if (!flag) {

                try {
                    el.parentNode.removeChild(el)
                } catch (e) {

                }
            }
            ;
        },
    });
    // 多个权限验证，全部满足则显示（v-auth-all="[xxx,xxx]"）
    app.directive('auth-all', {
        mounted(el, binding) {
            const stores = useUserInfo();

            // 用户 1 不检查
            if (stores.userInfos.userID == 1) {
                return;
            }

            // 角色 admin 不检查
            if (stores.userInfos.roles.some((v: string) => v === "admin")) {
                return;
            }

            const flag = judementSameArr(binding.value, stores.userInfos.permissions);
            if (!flag) el.parentNode.removeChild(el);
        },
    });
}
