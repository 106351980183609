<template>
<el-config-provider :size="getGlobalComponentSize" :locale="zhCn">
    <router-view v-show="themeConfig.lockScreenTime > 1" />
    <LockScreen v-if="themeConfig.isLockScreen" />
    <Setings ref="setingsRef" v-show="themeConfig.lockScreenTime > 1" />
    <CloseFull v-if="!themeConfig.isLockScreen" />

    <beautiful-chat v-if="state.chatShow" :participants="state.participants" :titleImageUrl="state.titleImageUrl" :onMessageWasSent="onMessageWasSent" :messageList="state.messageList" :newMessagesCount="state.newMessagesCount" :isOpen="state.isChatOpen" :close="closeChat" :open="openChat" :showEmoji="true" :showEmojiInText="true" :showFile="true" :showEdition="true" :showDeletion="true" :deletionConfirmation="true" :showTypingIndicator="state.showTypingIndicator" :showLauncher="true" title="Minion" :showCloseButton="true" :colors="state.colors" :alwaysScrollToBottom="state.alwaysScrollToBottom" :disableUserListToggle="true" :showHeader="true" :messageStyling="state.messageStyling" @onType="handleOnType" @edit="editMessage" />
</el-config-provider>
</template>

<script lang="ts">
import {
    computed,
    ref,
    getCurrentInstance,
    onBeforeMount,
    onMounted,
    onUnmounted,
    nextTick,
    defineComponent,
    watch,
    reactive,
    toRefs
} from 'vue';

import axios from 'axios'

import protobuf from '/@/proto/proto.js'

import {
    useRoute
} from 'vue-router';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import {
    storeToRefs
} from 'pinia';
import {
    useTagsViewRoutes
} from '/@/stores/tagsViewRoutes';
import {
    useThemeConfig
} from '/@/stores/themeConfig';
import other from '/@/utils/other';
import {
    Local,
    Session
} from '/@/utils/storage';
import setIntroduction from '/@/utils/setIconfont';
import LockScreen from '/@/layout/lockScreen/index.vue';
import Setings from '/@/layout/navBars/breadcrumb/setings.vue';
import CloseFull from '/@/layout/navBars/breadcrumb/closeFull.vue';

import {
    systemApi
} from './api/system';

let localPeer = null;
var peer : any =null;
var socket: WebSocket | null = null;
var reconnectTimeoutObj: string | number | NodeJS.Timeout | null | undefined = null;
import mitt from '/@/utils/eventbus'
var lockConnection = false;

var heartCheck = {
    timeout: 10000,
    timeoutObj: null,
    serverTimeoutObj: null,
    num: 3,
    start: function () {
        var self = this;
        var _num = this.num
        this.timeoutObj && clearTimeout(this.timeoutObj);
        this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
        this.timeoutObj = setTimeout(function () {
            //这里发送一个心跳，后端收到后，返回一个心跳消息，
            //onmessage拿到返回的心跳就说明连接正常
            let data = {
                type: "heatbeat",
                content: "ping",
            }

            if (socket.readyState === 1) {

                let message = protobuf.lookup("protocol.Message")
                const messagePB = message.create(data)
                socket.send(message.encode(messagePB).finish())
            }

            self.serverTimeoutObj = setTimeout(function () {
                _num--
                if (_num <= 0) {
                    console.log("the ping num is more then 3, close socket!")
                    socket.close();
                }
            }, self.timeout);

        }, this.timeout)
    }
}

export default defineComponent({
    name: 'app',
    components: {
        LockScreen,
        Setings,
        CloseFull
    },
    setup() {
        const {
            proxy
        } = < any > getCurrentInstance();
        const setingsRef = ref();
        const route = useRoute();

        const stores = useTagsViewRoutes();
        const storesThemeConfig = useThemeConfig();

        const state = reactive({
            currentScreen:{
                width:1024,
            },
            chatShow: false,
            participants: [{
                    id: 'user1',
                    name: 'Matteo',
                    imageUrl: "https://minion-public.oss-cn-shanghai.aliyuncs.com/images/zhqq56l43n1667464363183.jpg?x-oss-process=style/avatar_fixed_size",
                },
                {
                    id: 'me',
                    name: 'Agent',
                    imageUrl: "https://minion-public.oss-cn-shanghai.aliyuncs.com/images/zhqq56l43n1667464363183.jpg?x-oss-process=style/avatar_fixed_siz",
                }
            ], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
            titleImageUrl: "https://minion.al8l.com/data/public/2xj7c0dc3ue1667446992661%20(1).jpg?x-oss-process=style/avatar_fixed_size",
            messageList: [{
                type: 'text',
                author: 'user1',
                data: {
                    text: `Welcome`
                }
            }], // the list of the messages to show, can be paginated and adjusted dynamically
            newMessagesCount: 0,
            isChatOpen: false, // to determine whether the chat window should be open or closed
            showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
            icons: '',
            colors: {
                header: {
                    bg: '#4e8cff',
                    text: '#ffffff'
                },
                launcher: {
                    bg: '#4e8cff'
                },
                messageList: {
                    bg: '#ffffff'
                },
                sentMessage: {
                    bg: '#4e8cff',
                    text: '#ffffff'
                },
                receivedMessage: {
                    bg: '#eaeaea',
                    text: '#222222'
                },
                userInput: {
                    bg: '#f4f7f9',
                    text: '#565867'
                }
            }, // specifies the color scheme for the component
            alwaysScrollToBottom: false, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
            messageStyling: true // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
        });

        const {
            themeConfig
        } = storeToRefs(storesThemeConfig);
        // 获取全局组件大小
        const getGlobalComponentSize = computed(() => {
            return other.globalComponentSize();
        });
        // 布局配置弹窗打开
        const openSetingsDrawer = () => {
            setingsRef.value.openDrawer();
        };
        // 设置初始化，防止刷新时恢复默认
        onBeforeMount(() => {
            // 设置批量第三方 icon 图标
            setIntroduction.cssCdn();
            // 设置批量第三方 jsf
            setIntroduction.jsCdn();
        });

        const webrtcConnection= () =>{
            window.peer.onicecandidate = (e: { candidate: any; }) => {
                if (e.candidate) {
                // rtcType参数默认是对端值为answer，如果是发起端，会将值设置为offer
                let candidate = {
                    type: 'answer_ice',
                    iceCandidate: e.candidate
                }
                let message = {
                    content: JSON.stringify(candidate),
                    type: 10,
                }
                sendMessage1(message);
            }
            
            };
        
            /**
             * 当连接成功后，从里面获取语音视频流
             * @param {包含语音视频流} e 
             */
             window.peer.ontrack = (e) => {
                if (e && e.streams) {
                    if (1) {
                        let remoteVideo = document.getElementById("remoteVideoReceiver");
                        remoteVideo.srcObject = e.streams[0];
                    } else {
                        let remoteAudio = document.getElementById("audioPhone");
                        remoteAudio.srcObject = e.streams[0];
                    }
                }
            };
        }


    const stopVideoOnline = () => {
        let preview1 = document.getElementById("preview");
        
        if (preview1 && preview1.srcObject && preview1.srcObject.getTracks()) {
            preview1.srcObject.getTracks().forEach((track) => track.stop());
        }
    }

    const  webrtcConnection1 = () => {
       
           window.localPeer = new RTCPeerConnection();
       /**
        * 对等方收到ice信息后，通过调用 addIceCandidate 将接收的候选者信息传递给浏览器的ICE代理。
        * @param {候选人信息} e 
        */
        window.localPeer.onicecandidate = (e) => {
           if (e.candidate) {
               // rtcType参数默认是对端值为answer，如果是发起端，会将值设置为offer
               let candidate = {
                   type: 'offer_ice',
                   iceCandidate: e.candidate
               }
               let message = {
                   content: JSON.stringify(candidate),
                   type: 5,
               }
       
               sendMessage1(message);
           }
       
       };
       
       /**
        * 当连接成功后，从里面获取语音视频流
        * @param {包含语音视频流} e 
        */
        window.localPeer.ontrack = (e) => {
           if (e && e.streams) {
               let remoteVideo = document.getElementById("remoteVideoSender");
               remoteVideo.srcObject = e.streams[0];
           }
       };
       }
       
  const     startAudioOnline = () => {
        
    webrtcConnection1();

        navigator.mediaDevices
            .getUserMedia({
                audio: true,
                video: false,
            }).then((stream) => {
                stream.getTracks().forEach(track => {
                    window.localPeer.addTrack(track, stream);
                });

                // 一定注意：需要将该动作，放在这里面，即流获取成功后，再进行offer创建。不然不能获取到流，从而不能播放视频。
                window.localPeer.createOffer()
                    .then(offer => {
                        window.localPeer.setLocalDescription(offer);
                        let data = {
                            content: JSON.stringify(offer),
                            type: 6,   // 消息传输类型
                        }

                        console.log(data,6666666)
                        sendMessage1(data);
                    });
            });
    }

    const  startVideoOnline = () => {
        
        webrtcConnection1();
 

        navigator.mediaDevices
            .getUserMedia({
                audio: true,
                video: true,
            }).then((stream) => {
                let preview = document.getElementById("localPreviewSender");

                preview.srcObject = stream;
                stream.getTracks().forEach(track => {
                    window.localPeer.addTrack(track, stream);
                });

                // 一定注意：需要将该动作，放在这里面，即流获取成功后，再进行offer创建。不然不能获取到流，从而不能播放视频。
                window.localPeer.createOffer()
                    .then(offer => {
                        console.log(offer,'aaa')
                        window.localPeer.setLocalDescription(offer);
                       
                        let data = {
                            type: 7,
                            content: JSON.stringify(offer), 
                        }
                        
                        sendMessage1(data);
                    });
            });
 
    }



 const   sendMessage1 = (messageData) => {
       
        var from_id = localStorage.getItem("uid") + "";

                var to_id = from_id == "4" ? "1" : "4";

                    let data = {
                        fromUser: from_id,
                        toUser: to_id,
                        type: messageData.type,
                        mode: 0,
                        content: messageData.content,
                        url: "",
                    }

                    let message1 = protobuf.lookup("protocol.Message")
                    const messagePB = message1.create(data)
                      console.log(data.type,'bbb')
                    socket.send(message1.encode(messagePB).finish())

 
    }

    const dealMediaCall = (message) => {
        if (message.contentType === Constant.DIAL_AUDIO_ONLINE || message.contentType === Constant.DIAL_VIDEO_ONLINE) {
            this.setState({
                videoCallModal: true,
                callName: message.fromUsername,
                fromUserUuid: message.from,
            })
            return;
        }

        if (message.contentType === Constant.CANCELL_AUDIO_ONLINE || message.contentType === Constant.CANCELL_VIDEO_ONLINE) {
            this.setState({
                videoCallModal: false,
            })
            return;
        }

        if (message.contentType === Constant.REJECT_AUDIO_ONLINE || message.contentType === Constant.REJECT_VIDEO_ONLINE) {
            let media = {
                ...this.props.media,
                mediaReject: true,
            }
            this.props.setMedia(media);
            return;
        }

        if (message.contentType === Constant.ACCEPT_VIDEO_ONLINE || message.contentType === Constant.ACCEPT_AUDIO_ONLINE) {
            let media = {
                ...this.props.media,
                mediaConnected: true,
            }
            this.props.setMedia(media);
        }
    }


    const dealWebRtcMessage = (messagePB) => {
        // if (messagePB.contentType >= Constant.DIAL_MEDIA_START && messagePB.contentType <= Constant.DIAL_MEDIA_END) {
        //     dealMediaCall(messagePB);
        //     return;
        // }
        const { type, sdp, iceCandidate } = JSON.parse(messagePB.content);
 

        console.log(type,sdp,iceCandidate,'ddddd')

        if (type === "answer") {
            const answerSdp = new RTCSessionDescription({ type, sdp });
            window.localPeer.setRemoteDescription(answerSdp)
        } else if (type === "answer_ice") {
            window.localPeer.addIceCandidate(iceCandidate)
        } else if (type === "offer_ice") {
            window.peer.addIceCandidate(iceCandidate)
        } else if (type === "offer") {
            let preview

            let video = false;

            if (messagePB.type === 7) { 
                video = true
                 
            } 

            console.log(video)
            
            navigator.mediaDevices
                .getUserMedia({
                    audio: true,
                    video: video,
                }).then((stream) => { 

                    console.log(messagePB.type)
            
            if (messagePB.type === 7) {
                preview = document.getElementById("localVideoReceiver");
              
                 
            } else {
                preview = document.getElementById("audioPhone");
               
            }
            console.log(preview,messagePB.type,3333)

                    preview.srcObject = stream;
                    stream.getTracks().forEach(track => {
                        window.peer.addTrack(track, stream);
                    });

                    // 一定注意：需要将该动作，放在这里面，即流获取成功后，再进行answer创建。不然不能获取到流，从而不能播放视频。
                    const offerSdp = new RTCSessionDescription({ type, sdp });
                    window.peer.setRemoteDescription(offerSdp)
                        .then(() => {
                            window.peer.createAnswer().then(answer => {
                                window.peer.setLocalDescription(answer)

                                let message = {
                                    content: JSON.stringify(answer),
                                    type: messagePB.type
                                }

                                sendMessage1(message);
                            })
                        });
                });
        }
    }

        const connection = () => {
            // if (!sessionStorage.userInfo) {
            //     return;
            // }

            window.peer = new RTCPeerConnection();
            var image = document.getElementById('receiver');
 
         //   var userInfo = JSON.parse(sessionStorage.userInfo);

            var user_id = localStorage.getItem("uid") + "";

            if (!user_id) {
                return;
            }

            var domain =
                import.meta.env.VITE_SOCKET_DOMAIN;

            var protocol = location.protocol === 'https:' ? 'wss' : 'ws';
           
            socket = new WebSocket(`${protocol}://${domain}/socket.io?user=${user_id}`);

            window.socket = socket

            //连接打开时触发 
            socket.onopen = function (evt) {
                console.log("Connection open ...");
                state.chatShow = true;
                heartCheck.start()

                webrtcConnection()
            };

            //接收到消息时触发  
            socket.onmessage = function (message) {
                heartCheck.start();

                let messageProto = protobuf.lookup("protocol.Message")

                const reader = new FileReader();

                reader.readAsArrayBuffer(message.data);

                reader.onload = ((event) => {
                    // var result = JSON.parse(reader.result);

                    // console.log(result)

                    let messagePB = messageProto.decode(new Uint8Array(event.target.result))

                    console.log('AAA', messagePB)

                    console.log(mitt.emit("why", {
                        name: 'why',
                        age: 19
                    }))

                    if (messagePB.type === 0) {
                        return;
                    }

                    state.newMessagesCount = state.isChatOpen ? state.newMessagesCount : state.newMessagesCount + 1

                    if (messagePB.type == 1) {
                        onMessageWasSent({
                            author: 'user1',
                            type: 'text',
                            data: {
                                text: messagePB.content
                            }
                        })
                    }

                    if (messagePB.type == 2) {
                        onMessageWasSent({
                            author: 'user1',
                            type: 'file',
                            data: {
                                file: {
                                    url: messagePB.url,
                                    name: messagePB.url,
                                }
                            }
                        })
                    }

                    if(messagePB.type === 6 ){ 
                        console.log(212312312)
                         //dealWebRtcMessage(messagePB);
                         return; 
                    }

                    if(messagePB.type === 7 ){  
                         mitt.emit("video", messagePB);
                         return; 
                    }

                    if(messagePB.type === 8 ){ 
                         //dealWebRtcMessage(messagePB);
                         return; 
                    }

                    if (messagePB.type === 9) {
                        console.log('ccc')
                        mitt.emit("share", messagePB);
                        return;
                    }

                })

                console.log("Received Message: " + message.data);
            };

            socket.onerror = function (event) {
                console.log(event)
                reconnect();
            }

            //连接关闭时触发  
            socket.onclose = function (event) {
                console.log("Connection closed.");
                state.chatShow = false;
                reconnect();
            };

            const reconnect = () => {
                if (lockConnection) return;
                lockConnection = true

                reconnectTimeoutObj && clearTimeout(reconnectTimeoutObj)

                reconnectTimeoutObj = setTimeout(() => {
                    if (socket.readyState !== 1) {
                        connection()
                    }
                    lockConnection = false
                }, 10000)
            }
        }

        // 页面加载时
        onMounted(() => {

            window.onbeforeunload = function (event)
            {   
                window.socket.close();    
            }

            mitt.on("method",(res: any)=>{
                switch(res.name){
                    case "startShareOnline":
                        startShareOnline();
                        break;
                    case "startVideoOnline":
                        startVideoOnline();
                        break
                    case "startAudioOnline":
                        startAudioOnline();
                        break
                    case "stopVideoOnline":
                        break
                }
            });

            nextTick(() => {
                // 监听布局配置弹窗点击打开
                proxy.mittBus.on('openSetingsDrawer', () => {
                    openSetingsDrawer();
                });
                // 获取缓存中的布局配置
                if (Local.get('themeConfig')) {
                    storesThemeConfig.setThemeConfig(Local.get('themeConfig'));
                    document.documentElement.style.cssText = Local.get('themeConfigStyle');
                }
                // 获取缓存中的全屏配置
                if (Session.get('isTagsViewCurrenFull')) {
                    stores.setCurrenFullscreen(Session.get('isTagsViewCurrenFull'));
                }
            });
            
          // startShareOnline();

            if(     localStorage.getItem("uid") =='4'){
             //   startVideoOnline();
              //  startAudioOnline();


            }
          
            
            var env = location.port == "9000" ? 'development':'production';

            if(env == 'development'){
                //connection();
            }
        });
        // 页面销毁时，关闭监听布局配置/i18n监听
        onUnmounted(() => {
            proxy.mittBus.off('openSetingsDrawer', () => {});


            
        });
        // 监听路由的变化，设置网站标题
        watch(
            () => route.path,
            () => {
                other.useTitle();
            }, {
                deep: true,
            }
        );

        const sendMessage = (text: string) => {

            console.log(text, 121212121231312)
            if (text.length > 0) {
                //state.newMessagesCount = state.isChatOpen ? state.newMessagesCount : state.newMessagesCount + 1

                onMessageWasSent({
                    author: 'user2',
                    type: 'text',
                    data: {
                        text
                    }
                })
            }
        }

        const onMessageWasSent = (message: any) => {
            console.log(message, 222222)

            if (message.author == 'me') {
                var userInfo = JSON.parse(sessionStorage.userInfo);

                if (message.type == 'file') {

                    let formData = new FormData();

                    const config = {
                        headers: {}
                    };

                    systemApi().getFilePolicy({
                        auth: 'public'
                    }).then(res => {

                        formData.append("key", message.data.file.name);
                        formData.append("Policy", res.Policy);
                        formData.append("Signature", res.Signature);
                        formData.append("OSSAccessKeyId", res.OSSAccessKeyId);
                        formData.append("success_action_status", res.success_action_status);
                        formData.append("callback", res.callback);
                        formData.append("file", message.data.file, message.data.file.name);

                        axios
                            .post("https://minion-public.oss-cn-shanghai.aliyuncs.com", formData, config)
                            .then(function (response) {
                                console.log();

                                var from_id = userInfo.userID + "";
                                var to_id = from_id == "4" ? "1" : "4";

                                let data = {
                                    fromUser: from_id,
                                    toUser: to_id,
                                    type: 2,
                                    mode: 0,
                                    content: "",
                                    url: response.data.url,
                                }

                                var filename = message.data.file.name

                                delete message.data.file

                                message.data.file = {
                                    url: data.url,
                                    name: filename
                                }

                                var message2 = {
                                    author: 'me',
                                    type: 'file',
                                    data: {
                                        file: {
                                            url: data.url,
                                            name: filename
                                        }
                                    }
                                }
                                message = JSON.parse(JSON.stringify(message))

                                console.log(message, 666666666)

                                let message1 = protobuf.lookup("protocol.Message")
                                const messagePB = message1.create(data)

                                console.log(messagePB, 1111)

                                socket.send(message1.encode(messagePB).finish())

                                state.messageList = [...state.messageList, message2]

                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    });

                } else {
                    var from_id = userInfo.userID + "";
                    var to_id = from_id == "4" ? "1" : "4";

                    let data = {
                        fromUser: from_id,
                        toUser: to_id,
                        type: 1,
                        mode: 0,
                        content: message.data.text,
                        url: "",
                    }

                    let message1 = protobuf.lookup("protocol.Message")
                    const messagePB = message1.create(data)

                    socket.send(message1.encode(messagePB).finish())

                    state.messageList = [...state.messageList, message]
                }
            } else {
                state.messageList = [...state.messageList, message]
            }

            console.log(message, 33333333)

        }

        const openChat = () => {

            // called when the user clicks on the fab button to open the chat
            state.isChatOpen = true

            state.newMessagesCount = 0
        }
        const closeChat = () => {
            // called when the user clicks on the botton to close the chat
            state.isChatOpen = false
        }
        const handleScrollToTop = () => {
            // called when the user scrolls message list to top
            // leverage pagination for loading another page of messages
        }
        const handleOnType = () => {
            console.log('Emit typing event')
        }
        const editMessage = (message: {
            id: any;data: {
                text: any;
            };
        }) => {
            const m = state.messageList.find((m: {
                id: any;
            }) => m.id === message.id);
            m.isEdited = true;
            m.data.text = message.data.text;
        }

        return {
            zhCn,
            themeConfig,
            setingsRef,
            getGlobalComponentSize,
            state,
            sendMessage,
            onMessageWasSent,
            openChat,
            closeChat,
            handleScrollToTop,
            handleOnType,
            editMessage,

        };
    },
    methods: {

    }
});
</script>

<style>
.sc-chat-window {
    z-index: 999;
}
</style>
