import axios from 'axios';
import {ElMessage, ElMessageBox} from 'element-plus';
import {Session} from '/@/utils/storage';


interface Result {
    code: number;
    msg: string;
}

interface ResultData<T = any> extends Result {
    data?: T;
}

const env = location.port == '9000' ? 'development':'production';

// 配置新建一个 axios 实例
const service = axios.create({
    baseURL: import.meta.env.VITE_API_URL as any,
    timeout: 50000,
    headers: {'Content-Type': 'application/json'},
});

// 添加请求拦截器
service.interceptors.request.use(
    (config) => {
        // 在发送请求之前做些什么 token
        if (Session.get('token')) {
            (<any>config.headers).common['Authorization'] = 'Bearer ' + `${Session.get('token')}`;
        }

        return config;
    },
    (error) => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

const styles = {
    "get":"color:green;font-size:18px;",
    "post":"color:blue;font-size:18px;",
    "put":"color:yellow;font-size:18px;",
    "patch":"color:yellow;font-size:18px;",
    "delete":"color:red;font-size:18px;",
    "options":"color:black;font-size:18px;",
}

// 添加响应拦截器
service.interceptors.response.use(
    (response) => {
        // 对响应数据做点什么
        const res = <ResultData>response.data;

        if(env =='development'){
            // @ts-ignore
            console.group("%c " + response.config.method?.toUpperCase() , styles[response.config.method])

            console.log('Url::', response.config.url);
            console.log('Code::', response.status);

            console.log('Params::', response.config.params);
            try{
                console.log('Data::', JSON.parse(response.config.data));
            }catch(e){}

            console.log('Response::', response.data);

            console.groupEnd();
        }

        return response;
    },
    (error) => {
        if(error.response.status == 401){
            Session.clear(); // 清除浏览器全部临时缓存
            ElMessageBox.alert('登陆信息过期，请重新登录', '提示', {})
                .then(() => {
                    window.location.href = '/'; // 去登录页
                })
                .catch(() => {
                    window.location.href = '/'; // 去登录页
                });
        }

        if(error.response.status == 403){
            ElMessage.error('[ ' +error.response.config.url + ' ]无权限');

            return Promise.reject(error);
        }

        // 对响应错误做点什么
        if (error.message.indexOf('timeout') != -1) {
            ElMessage.error('网络超时');
        } else if (error.message == 'Network Error') {
            ElMessage.error('网络连接错误');
        } else {
            if (error.response.data) ElMessage.error(error.response.statusText);
            else ElMessage.error('接口路径找不到');
        }
        return Promise.reject(error);
    }
);

// 导出 axios 实例
export default service;
