/**
 * 判断两数组字符串是否相同（用于按钮权限验证），数组字符串中存在相同时会自动去重（按钮权限标识不会重复）
 * @param news 新数据
 * @param old 源数据
 * @returns 两数组相同返回 `true`，反之则反
 */
export function judementSameArr(newArr: unknown[] | string[], oldArr: string[]): boolean {
    const news = removeDuplicate(newArr);
    const olds = removeDuplicate(oldArr);
    let count = 0;
    const leng = news.length;
    for (let i in olds) {
        for (let j in news) {
            if (olds[i] === news[j]) count++;
        }
    }
    return count === leng ? true : false;
}

// cascader 格式化
export function cascaderItemsLabel(data: [], ids: number[]): string {
    let labels = []
    ids.forEach(res => {
        Object.keys(data).map((key) => {
            if (data[key].id === res) {
                labels.push(data[key].name)
                return false
            }
        })
    })
    return labels.join("/")
}

// 回显数据字典
export function selectDictLabel(data: {}, value: number):string {
    var actions = []
    Object.keys(data).map((key) => {
        if (data[key].value === value) {
            actions.push(data[key].label)
            return false
        }
    })
    return actions.join('')
}


/**
 * 判断两个对象是否相同
 * @param a 要比较的对象一
 * @param b 要比较的对象二
 * @returns 相同返回 true，反之则反
 */
export function isObjectValueEqual(a: { [key: string]: any }, b: { [key: string]: any }) {
    if (!a || !b) return false;
    let aProps = Object.getOwnPropertyNames(a);
    let bProps = Object.getOwnPropertyNames(b);
    if (aProps.length != bProps.length) return false;
    for (let i = 0; i < aProps.length; i++) {
        let propName = aProps[i];
        let propA = a[propName];
        let propB = b[propName];
        if (!b.hasOwnProperty(propName)) return false;
        if (propA instanceof Object) {
            if (!isObjectValueEqual(propA, propB)) return false;
        } else if (propA !== propB) {
            return false;
        }
    }
    return true;
}

/**
 * 数组、数组对象去重
 * @param arr 数组内容
 * @param attr 需要去重的键值（数组对象）
 * @returns
 */
export function removeDuplicate(arr: any, attr?: string) {
    if (!arr && !arr.length) {
        return arr;
    } else {
        if (attr) {
            const obj: any = {};
            const newArr = arr.reduce((cur: any, item: any) => {
                obj[item[attr]] ? '' : (obj[item[attr]] = true && item[attr] && cur.push(item));
                return cur;
            }, []);
            return newArr;
        } else {
            return Array.from(new Set([...arr]));
        }
    }
}

export  function treeToArray(tree) {
    return tree.reduce((res, item) => {
        const { children, ...i } = item
        return res.concat(i, children && children.length ? treeToArray(children) : [])
    }, [])
}

interface treeList {
    id: number,
    children: treeList[]
}

//获取节点的所有父节点
export function getTreeParentIds(list: treeList[] | unknown[], id: number): number[] {
    for (let i in list) {
        if (list[i].id === id) {
            //查询到返回该数组对象
            return [list[i].id];
        }
        if (list[i].children) {
            let node = getTreeParentIds(list[i].children, id);
            if (node !== undefined) {
                //查询到把父节点连起来
                return node.concat(list[i].id);
            }
        }
    }
}

