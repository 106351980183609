import {defineStore} from 'pinia';
// import Cookies from 'js-cookie';
import {UserInfosStates} from './interface';
import {Session} from '/@/utils/storage';
import {useLoginApi} from "/@/api/login";

/**
 * 用户信息
 * @methods setUserInfos 设置用户信息
 */
export const useUserInfo = defineStore('userInfo', {
    state: (): UserInfosStates => ({
        userInfos: {
            account: '',
            avatar: '',
            roles: [],
            permissions: [],
            userID: 0,
            lastLoginAt: '',
            lastLoginIP: '',
        },
    }),
    actions: {
        async setUserInfos() {
            if (Session.get('userInfo')) {
                this.userInfos = Session.get('userInfo');
            }else{
                //获取新的用户数据
                useLoginApi().getUserInfos().then(res=>{
                    //if (res.code === 10000) {
                         

                        const userInfos = {
                            account: res.data.name,
                            roles: res.data.roles || [],
                            avatar: res.data.avatar || '',
                            lastLoginAt: res.data.lastLoginAt || '',
                            lastLoginIP: res.data.lastLoginIp || '',
                            permissions: res.data.permissions || [],
                            userID: res.data.id,
                        }

                        Session.set('userInfo',userInfos)
                        this.userInfos = userInfos
                    //}
                })
            }
        },
        async getUserInfo(){
            debugger
        }
        // 模拟接口数据
        // async getApiUserInfo() {
        //
        //     // return new Promise((resolve) => {
        //     // 	setTimeout(() => {
        //     // 		// 模拟数据，请求接口时，记得删除多余代码及对应依赖的引入
        //     // 		const userName = Cookies.get('userName');
        //     // 		// 模拟数据
        //     // 		let defaultRoles: Array<string> = [];
        //     // 		let defaultAuthBtnList: Array<string> = [];
        //     // 		// admin 页面权限标识，对应路由 meta.roles，用于控制路由的显示/隐藏
        //     // 		let adminRoles: Array<string> = ['admin'];
        //     // 		// admin 按钮权限标识
        //     // 		let adminAuthBtnList: Array<string> = ['btn.add', 'btn.del', 'btn.edit', 'btn.link'];
        //     // 		// test 页面权限标识，对应路由 meta.roles，用于控制路由的显示/隐藏
        //     // 		let testRoles: Array<string> = ['common'];
        //     // 		// test 按钮权限标识
        //     // 		let testAuthBtnList: Array<string> = ['btn.add', 'btn.link'];
        //     // 		// 不同用户模拟不同的用户权限
        //     // 		if (userName === 'admin') {
        //     // 			defaultRoles = adminRoles;
        //     // 			defaultAuthBtnList = adminAuthBtnList;
        //     // 		} else {
        //     // 			defaultRoles = testRoles;
        //     // 			defaultAuthBtnList = testAuthBtnList;
        //     // 		}
        //     // 		// 用户信息模拟数据
        //     // 		const userInfos = {
        //     // 			userName: userName,
        //     // 			photo:
        //     // 				userName === 'admin'
        //     // 					? 'https://img2.baidu.com/it/u=1978192862,2048448374&fm=253&fmt=auto&app=138&f=JPEG?w=504&h=500'
        //     // 					: 'https://img2.baidu.com/it/u=2370931438,70387529&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
        //     // 			time: new Date().getTime(),
        //     // 			roles: defaultRoles,
        //     // 			authBtnList: defaultAuthBtnList,
        //     // 		};
        //     // 		// 存储用户信息到浏览器缓存
        //     // 		Session.set('userInfo', userInfos);
        //     // 		resolve(userInfos);
        //     // 	}, 3000);
        //     // });
        // },
    },
});
